import React, { useEffect, useRef } from "react";
import CounterComponent from "./CounterComponent";
import { useScrollPosition } from "../hooks/useScrollPosition";
import Typed from "typed.js";
import AOS from "aos";
import landimg from "../images/Edited/Services Page/Banner_Image.jpg";
import ortho from "../images/NextGen/Our Services Page/Ortho_Sports.jpg";
import neuro from "../images/NextGen/Our Services Page/Neuro.jpg";
import ultra from "../images/NextGen/Our Services Page/Therapeutic_Ultrasound.jpg";
import ift from "../images/NextGen/Our Services Page/IFT_&_sEMG.jpg";
import laser from "../images/NextGen/Our Services Page/Laser_Therapy.jpg";
import soft from "../images/NextGen/Our Services Page/Soft_Tissue_Manipulation.jpg";
import joint from "../images/NextGen/Our Services Page/Joint_Mobilisation.jpg";
import exercise from "../images/NextGen/Our Services Page/Exercise_&_Pilates.jpg";
import manual from "../images/NextGen/Our Services Page/Manual_Therapy.jpg";
import TestimonialSlider from "./TestimonialSlider";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const el = useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
    });

    const typed = new Typed(el.current, {
      strings: ["services to ease your pain"], // Strings to show and erase
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      showCursor: true,
    });

    // Cleanup
    return () => {
      typed.destroy();
    };
  }, []);

  const scrollY = useScrollPosition();

  return (
    <>
      <div
        className="site-blocks-cover overlay"
        style={{
          backgroundImage: `url(${landimg})`,
          backgroundPosition: `center calc(70% + ${scrollY * 0.6}px)`,
          backgroundAttachment: "fixed",
        }}
        data-aos="fade"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-10">
              <div className="row justify-content-center mb-4">
                <div className="col-md-10 text-center">
                  <h1 data-aos="fade-up" className="mb-5">
                    We do our <span ref={el} className="typed-words" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section block-services-1">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2 className="site-section-heading text-center font-secondary text-black">
                Our Services
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={ortho} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Ortho & Sports Physiotherapy</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p>
                  </p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={neuro} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Neuro Physiotherapy</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p>
                  </p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={ultra} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Therapeutic Ultrasound</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={ift} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">IFT & sEMG</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={laser} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Laser Therapy</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={soft} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Soft Tissue Manipulation</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={joint} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Joint Mobilisation</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={exercise} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Exercise & Pilates</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-4 mb-lg-4 col-sm-6 col-md-6 col-lg-3">
              <div className="block-service-1-card">
                <a href="/" className="thumbnail-link d-block mb-4">
                  <img src={manual} alt="" className="img-fluid" />
                </a>
                <h3 className="block-service-1-heading mb-3">
                  <a href="/">Manual Therapy</a>
                </h3>
                <div className="block-service-1-excerpt">
                  <p></p>
                </div>
                <p>
                  <a
                    href="tel:+91 9205 546 009"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>Call Now</span>{" "}
                    <span className="icon-keyboard_arrow_right icon" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CounterComponent />
      <TestimonialSlider />
    </>
  );
};

export default Services;

import React, { useEffect, useRef, useState } from "react";
import { useScrollPosition } from "../hooks/useScrollPosition";
import Typed from "typed.js";
import AOS from "aos";
import landimg from "../images/Edited/Contact/Banner_Image.jpg";
import emailjs from "@emailjs/browser";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.id]: event.target.value,
    });
  };
  const el = useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
    });

    const typed = new Typed(el.current, {
      strings: ["for home visits"], // Strings to show and erase
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      showCursor: true,
    });

    // Cleanup
    return () => {
      typed.destroy();
    };
  }, []);

  const scrollY = useScrollPosition();

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};

    if (!formState.firstName) formErrors.firstName = "First name is required!";
    if (!formState.lastName) formErrors.lastName = "Last name is required!";
    if (!formState.email) {
      formErrors.email = "Email is required!";
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      formErrors.email = "Email is invalid!";
    }
    if (!formState.subject) formErrors.subject = "Subject is required!";

    setErrors(formErrors);

    // If no errors, return true, else return false
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const subject = document.getElementById("subject").value;
    const message = document.getElementById("message").value;

    emailjs
      .send(
        "service_ngprc",
        "template_ngprc",
        {
          from_name: `${firstName} ${lastName}`,
          from_email: email,
          subject: subject,
          message: message,
        },
        "rXpXWGke0yxGQ2yYM"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormState({
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
          });
          alert("Message sent successfully!");
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <>
      <div
        className="site-blocks-cover overlay"
        style={{
          backgroundImage: `url(${landimg})`,
          backgroundPosition: `center calc(60% + ${scrollY * 0.6}px)`,
          backgroundAttachment: "fixed",
        }}
        data-aos="fade"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-10">
              <div className="row justify-content-center mb-4">
                <div className="col-md-10 text-center">
                  <h1 data-aos="fade-up" className="mb-5">
                    Contact <span ref={el} className="typed-words" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-5" data-aos="fade">
              <form action="/" className="p-5 bg-white" onSubmit={handleSubmit}>
                <div className="row form-group">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="fname">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                      value={formState.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <p style={{ color: "red" }}>{errors.firstName}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="text-black" htmlFor="lname">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="form-control"
                      value={formState.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <p style={{ color: "red" }}>{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      value={formState.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="subject">
                      Subject
                    </label>
                    <input
                      type="subject"
                      id="subject"
                      className="form-control"
                      value={formState.subject}
                      onChange={handleChange}
                    />
                    {errors.subject && (
                      <p style={{ color: "red" }}>{errors.subject}</p>
                    )}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="message">
                      Description (Optional)
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      cols={30}
                      rows={7}
                      className="form-control"
                      placeholder="Write your notes or questions here..."
                      value={formState.message}
                      onChange={handleChange}
                    />
                    {errors.message && (
                      <p style={{ color: "red" }}>{errors.message}</p>
                    )}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12 d-flex justify-content-center">
                    <input
                      type="submit"
                      defaultValue="Send Message"
                      className="btn btn-pill btn-primary btn-md text-white"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5" data-aos="fade" data-aos-delay={100}>
              <div className="p-4 mb-3 bg-white">
                <p className="mb-0 font-weight-bold">Address</p>
                <p className="mb-4">
                  Commercial Shop/Dwelling No. 3, Block B, Ground Floor, Omaxe
                  India Trade Centre, Alpha 2, Greater Noida, U.P.
                </p>
                <p className="mb-0 font-weight-bold">Phone</p>
                <p className="mb-4">
                  <a href="tel:01206027137">0120 602 71 37</a> <br />
                  <a href="tel:+919205546009">+91 9205 546 009</a>
                </p>
                <p className="mb-0 font-weight-bold">Email Address</p>
                <p className="mb-0">
                  <a href="mailto:nextgenphysiotherapy@gmail.com">
                    nextgenphysiotherapy@gmail.com
                  </a>
                </p>
              </div>
              <div className="p-4 mb-3 bg-white">
                <h3 className="h5 text-black mb-3">Location</h3>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "60%",
                    height: 0,
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.108059602635!2d77.51785457519733!3d28.476292191159153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceb708f1c4807%3A0x9317ab784b849f43!2sNextGen%20Physiotherapy%20and%20Rehabilitation%20Centre!5e0!3m2!1sen!2sin!4v1713944789466!5m2!1sen!2sin"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: 0,
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    title="map"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

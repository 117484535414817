import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "../hooks/useScrollPosition";
import CounterComponent from "./CounterComponent";
import TestimonialSlider from "./TestimonialSlider";
import AOS from "aos";
import "aos/dist/aos.css";
import Typed from "typed.js";
import landimg from "../images/Edited/Landing page/Banner_Image.jpg";
import so1 from "../images/NextGen/Landing Page/Our_special_offers_section_box_1.jpg";
import so2 from "../images/image.jpg";
import wo1 from "../images/NextGen/Landing Page/whar_we_offer_landing_page_ortho_sports.jpg";
import wo2 from "../images/NextGen/Landing Page/What_we_offer_landing_page_neuro.jpg";
import wo3 from "../images/NextGen/Landing Page/What_we_offer_landing_page_Therapeutic_Ultrasound.jpg";
import emailjs from "@emailjs/browser";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const el = useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
    });

    const typed = new Typed(el.current, {
      strings: ["Reclaim Your Mobility"], // Strings to show and erase
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      showCursor: true,
    });

    // Cleanup
    return () => {
      typed.destroy();
    };
  }, []);

  const scrollY = useScrollPosition();

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const formErrors = {};

    if (!form.name) formErrors.name = "Name is required";
    if (!form.email) formErrors.email = "Email is required";
    if (!form.phone) formErrors.phone = "Phone is required";
    if (!form.location) formErrors.location = "Location is required";

    return formErrors;
  };

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    emailjs
      .send("service_ngprc", "template_ngprc_2", form, "rXpXWGke0yxGQ2yYM")
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setForm({
            name: "",
            email: "",
            phone: "",
            location: "",
          });
          alert("Booking request sent successfully!");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );

    setForm({
      name: "",
      email: "",
      phone: "",
      location: "",
    });
  };

  return (
    <>
      <>
        <div
          className="site-blocks-cover overlay"
          style={{
            backgroundImage: `url(${landimg})`,
            backgroundPosition: `center calc(100% + ${scrollY * 0.6}px)`,
            backgroundAttachment: "fixed",
          }}
          data-aos="fade"
        >
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-10">
                <div className="row justify-content-center mb-4">
                  <div className="col-md-10 text-center">
                    <h1 data-aos="fade-up" className="mb-5">
                      Where Pain Ends and Strength Begins. <br />{" "}
                      <span ref={el} className="typed-words" />
                    </h1>
                    <p data-aos="fade-up" data-aos-delay={100}>
                      <a
                        href="/services"
                        className="btn btn-primary btn-pill mb-5"
                      >
                        Get Started
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-quick-info-2">
          <div className="container-fluid" style={{ maxWidth: "90vw" }}>
            <div className="block-quick-info-2-inner rounded">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                  <div className="d-flex quick-info-2">
                    <span className="icon icon-home mr-3 mb-2" />
                    <div className="text pt-2">
                      <strong className="d-block heading">
                        Visit our Location
                      </strong>
                    </div>
                  </div>
                  <span
                    className="excerpt d-block text-left"
                    style={{ fontSize: "14px" }}
                  >
                    Commercial Shop/Dwelling No. 3, Block B, Ground Floor, Omaxe
                    India Trade Centre, Alpha 2, Greater Noida, U.P.
                  </span>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-2 mb-3 mb-lg-0">
                  <div className="d-flex quick-info-2">
                    <span className="icon icon-phone mr-3 mb-2" />
                    <div className="text pt-2">
                      <strong className="d-block heading">Call us today</strong>
                    </div>
                  </div>
                  <span className="excerpt text-left d-block">
                    <a href="tel:+919205546009">+91 9205 546 009</a> <br />
                    <a className="pl-2" href="tel:01206027137">
                      0120 602 71 37
                    </a>
                  </span>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                  <div className="d-flex quick-info-2">
                    <span className="icon icon-envelope mr-3 mb-2" />
                    <div className="text pt-2">
                      <strong className="d-block heading">
                        Send us a message
                      </strong>
                    </div>
                  </div>
                  <span className="excerpt">
                    <a href="mailto:nextgenphysiotherapy@gmail.com">
                      nextgenphysiotherapy@gmail.com
                    </a>
                  </span>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-2 mb-3 mb-lg-0">
                  <div className="d-flex quick-info-2">
                    <span className="icon icon-clock-o mr-3 mb-2" />
                    <div className="text pt-2">
                      <strong className="d-block heading">Opening hours</strong>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-left">
                    Monday to Saturday
                    <br />
                    9:00 AM to 9:00 PM
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-2 mb-3 mb-lg-0 text-center">
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill px-sm-2 pt-sm-3 px-4 py-2 "
                      onClick={() => {
                        setIsOpen(!isOpen);
                        if (isOpen) {
                          setErrors({});
                        }
                      }}
                    >
                      Make an Appointment
                      <br />
                      {isOpen ? (
                        <i className="icon-arrow_upward ml-2"></i>
                      ) : (
                        <i className="icon-arrow_downward ml-2"></i>
                      )}
                    </button>
                    {isOpen && (
                      <form className="pt-3" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder="Your name"
                            name="name"
                            value={form.name}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <div style={{ color: "red" }}>{errors.name}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control rounded-pill"
                            placeholder="Your email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div style={{ color: "red" }}>{errors.email}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="tel"
                            className="form-control rounded-pill"
                            placeholder="Your phone"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                          />
                          {errors.phone && (
                            <div style={{ color: "red" }}>{errors.phone}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder="Your location"
                            name="location"
                            value={form.location}
                            onChange={handleChange}
                          />
                          {errors.location && (
                            <div style={{ color: "red" }}>
                              {errors.location}
                            </div>
                          )}
                        </div>
                        <div className="form-group d-flex justify-content-center">
                          <button
                            type="submit"
                            className="btn btn-primary rounded-pill px-4 py-2"
                          >
                            Book Now
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        <div className="block-services-1 py-5">
          <div className="container-fluid">
            <div className="row">
              <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                <h3 className="mb-3">What We Offer</h3>
                <p>
                  Choosing the right physiotherapy provider is crucial for
                  effective treatment and recovery.
                </p>
                <p>
                  <Link
                    to="/services"
                    className="d-inline-flex align-items-center block-service-1-more"
                  >
                    <span>See all services</span>
                    <span className="icon-keyboard_arrow_right icon" />
                  </Link>
                </p>
              </div>
              <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                <div className="block-service-1-card">
                  <a href="/services" className="thumbnail-link d-block mb-4">
                    <img src={wo1} alt="" className="img-fluid" />
                  </a>
                  <h3 className="block-service-1-heading mb-3">
                    <a href="/services">Ortho & Sports Physiotherapy</a>
                  </h3>
                  <div className="block-service-1-excerpt">
                    <p></p>
                  </div>
                  <p>
                    <a
                      href="/services"
                      className="d-inline-flex align-items-center block-service-1-more"
                    >
                      <span>Find out more</span>{" "}
                      <span className="icon-keyboard_arrow_right icon" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                <div className="block-service-1-card">
                  <a href="/services" className="thumbnail-link d-block mb-4">
                    <img src={wo2} alt="" className="img-fluid" />
                  </a>
                  <h3 className="block-service-1-heading mb-3">
                    <a href="/services">Neuro Physiotherapy</a>
                  </h3>
                  <div className="block-service-1-excerpt">
                    <p></p>
                  </div>
                  <p>
                    <a
                      href="/services"
                      className="d-inline-flex align-items-center block-service-1-more"
                    >
                      <span>Find out more</span>{" "}
                      <span className="icon-keyboard_arrow_right icon" />
                    </a>
                  </p>
                </div>
              </div>
              <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                <div className="block-service-1-card">
                  <a href="/services" className="thumbnail-link d-block mb-4">
                    <img src={wo3} alt="" className="img-fluid" />
                  </a>
                  <h3 className="block-service-1-heading mb-3">
                    <a href="/services">Therapeutic Ultrasound</a>
                  </h3>
                  <div className="block-service-1-excerpt">
                    <p></p>
                  </div>
                  <p>
                    <a
                      href="/services"
                      className="d-inline-flex align-items-center block-service-1-more"
                    >
                      <span>Find out more</span>{" "}
                      <span className="icon-keyboard_arrow_right icon" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CounterComponent />
        <div className="site-section bg-light">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12 text-center">
                <h2 className="site-section-heading text-center font-secondary">
                  Are you suffering from any of these?
                </h2>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="d-block d-lg-flex">
              <div className="half-wrap d-block d-md-flex">
                <div className="half half1 bg-white d-block d-md-flex arrow-right">
                  <div className="text">
                    <h2>⦾ Ortho/Sports Physiotherapy</h2>
                    <ul>
                      <li>Sprain</li>
                      <li>Joint Injury</li>
                      <li>Shoulder Pain</li>
                      <li>Knee Pain</li>
                      <li>Fracture/Dislocation</li>
                      <li>Back/Neck Pain</li>
                      <li>Muscle Strain</li>
                      <li>Arthritis</li>
                      <li>Frozen Shoulder</li>
                      <li>Tennis Elbow</li>
                      <li>Cervical Pain</li>
                      <li>Lumbar Pain</li>
                      <li>Sports Injury</li>
                    </ul>
                  </div>
                </div>
                <div
                  className="half bg-img img"
                  style={{ backgroundImage: `url(${so1})` }}
                />
              </div>
              <div className="half-wrap d-block d-md-flex">
                <div className="half bg-white d-block d-md-flex arrow-right">
                  <div className="text">
                    <h2>⦾ Neuro Physiotherapy</h2>
                    <ul>
                      <li>Paralysis</li>
                      <li>Bell's Palsy</li>
                      <li>Wrist Drop</li>
                      <li>Foot Drop</li>
                      <li>L4 L5 Disc Pain</li>
                      <li>Sciatica Pain</li>
                    </ul>
                  </div>
                </div>
                <div
                  className="half bg-img img"
                  style={{ backgroundImage: `url(${so2})` }}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12 text-center">
                <Link
                  to="tel:+91 9205 546 009"
                  className="btn btn-outline-primary mt-5 btn-pill font-weight-bold"
                >
                  Call for Appointments
                </Link>
              </div>
            </div>
          </div>
        </div>
        <TestimonialSlider />
      </>
    </>
  );
};

export default Home;

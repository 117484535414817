import React, { useEffect, useRef } from "react";
import { useScrollPosition } from "../hooks/useScrollPosition";
import Typed from "typed.js";
import AOS from "aos";
import bannerimg from "../images/Edited/About Page/Banner_Image.jpg";
import sec1 from "../images/Edited/About Page/about us section_image 1.png";
import sec2 from "../images/Edited/About Page/About us section_image 2.png";
import person1 from "../images/Edited/About Page/Our team section_dr sujeet.png";
import person2 from "../images/Edited/About Page/Our team section_dr palak.png";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const el = useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
    });

    const typed = new Typed(el.current, {
      strings: ["Center"], // Strings to show and erase
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      showCursor: true,
    });

    // Cleanup
    return () => {
      typed.destroy();
    };
  }, []);

  const scrollY = useScrollPosition();

  return (
    <>
      <div
        className="site-blocks-cover overlay"
        style={{
          backgroundImage: `url(${bannerimg})`,
          backgroundPosition: `center calc(40% + ${scrollY * 0.6}px)`,
          backgroundAttachment: "fixed",
        }}
        data-aos="fade"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-10">
              <div className="row justify-content-center mb-4">
                <div className="col-md-10 text-center">
                  <h1 data-aos="fade-up" className="mb-5">
                    About our <span ref={el} className="typed-words" />
                  </h1>
                  <p data-aos="fade-up" data-aos-delay={100}>
                    <a href="/services" className="btn btn-primary btn-pill">
                      Get Started
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={sec1} alt="" className="img-fluid rounded mb-5 pb-5" />
              <img src={sec2} alt="" className="img-fluid rounded" />
            </div>
            <div className="col-md-5 ml-auto">
              <h2 className="text-primary mb-3">About Us</h2>
              <p className="lead text-justify">
                Welcome to NextGen Physiotherapy & Rehabilitation Center, where
                we are dedicated to enhance your well-being through personalized
                and expert care. Founded with a passion for promoting health and
                restoring function, we are committed to provide the highest
                quality physiotherapy services to our community.
              </p>
              <p className="mb-4 text-justify">
                At NextGen Physiotherapy & Rehabilitation Center, we believe in
                a holistic approach to healthcare, focusing on addressing the
                root cause of your condition rather than just treating the
                symptoms. Our team of experienced physiotherapists combines
                evidence-based practices with compassionate care to tailor
                treatment plans that meet your unique needs and goals.
              </p>
              <p className="text-justify">
                Whether you are recovering from an injury, managing a chronic
                condition, or seeking to optimize your physical performance, we
                are here to support you every step of the way. Our
                state-of-the-art facility is equipped with the latest
                technologies and tools to ensure that you receive the most
                effective and efficient care possible.
              </p>
              <p className="mb-4 text-justify">
                At NextGen Physiotherapy & Rehabilitation Center, your
                well-being is our top priority. We are dedicated to helping you
                reach your full potential and achieve optimal health and
                mobility. Discover the difference that personalized, expert
                physiotherapy care can make in your life.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 text-center mb-5">
              <h2 className="text-primary mb-3">Why Choose Us</h2>
              <p className="lead">
                Choosing the right physiotherapy provider is crucial for
                effective treatment and recovery.
              </p>
            </div>
            <ul className="ul-check list-unstyled success pl-4">
              <li>
                <h5>Excellence in Personalized Care</h5>
                <p className="mb-4 text-jusitfy">
                  Our clinic stands out with a team of highly qualified
                  specialists who are dedicated to offering personalized care
                  and comprehensive treatment plans.
                </p>
              </li>
              <li>
                <h5>Unique Approach for Patients</h5>
                <p className="mb-4 text-jusitfy">
                  We understand that every patient's journey is unique, and our
                  approach is tailored to meet individual needs and goals.
                </p>
              </li>
              <li>
                <h5>Advanced Technology for Optimal Care</h5>
                <p className="mb-4 text-jusitfy">
                  Our state-of-the-art facilities are equipped with the latest
                  technology to ensure the best possible outcomes.
                </p>
              </li>
              <li>
                <h5>Empowering Patients through Knowledge</h5>
                <p className="mb-4 text-jusitfy">
                  Moreover, we prioritize patient education, empowering you with
                  the knowledge to maintain and improve your health beyond our
                  clinic.
                </p>
              </li>
              <li>
                <h5>Excellence in Physiotherapy</h5>
                <p className="mb-4 text-jusitfy">
                  With a commitment to excellence and a passion for patient
                  well-being, we are the clear choice for those seeking quality
                  physiotherapy services.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="site-section pt-0">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center">
              <h2 className="text-primary mb-3">Our Team</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-5 text-center">
              <img
                src={person1}
                alt=""
                className="img-fluid rounded mb-3 w-50"
              />
              <h3 className="h4 pb-3">Dr. Sujeet Joseph Singh</h3>
              <p>
                (BPT, MPT - Sports) <br />
                Member of IAP
                <br />
                <span className="text-primary"> Senior Consultant/Therapist</span>
              </p>
              <ul className="text-left mx-auto exp1 ul-check list-unstyled success">
                <span className="font-weight-bold">Expertise In</span>
                <li>Manual Therapy Expert</li> 
                <li>Electrical Modalities Expert</li> 
                <li>Dry Needling</li> 
                <li>Cupping Therapy</li> 
              </ul>
            </div>
            <div className="col-md-6 col-lg-6 mb-4 mb-lg-5 text-center">
              <img
                src={person2}
                alt=""
                className="img-fluid rounded mb-3 w-50"
              />
              <h3 className="h4 pb-3">Dr. Palak Tiwari</h3>
              <p>
                (Bachelor's in Physiotherapy)
                <br />
                <span className="text-primary">
                  Assistant Consultant/Therapist
                </span>
              </p>
              <ul className="text-left mx-auto exp2 ul-check list-unstyled success">
                <span className="font-weight-bold">Expertise In</span>
                <li>Manual Therapy</li> 
                <li>Electrical Modalities</li> 
                <li>Dry Needling</li> 
                <li>Cupping Therapy</li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

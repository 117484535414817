import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyCaCcRvwb-sb1v9EcUGk7kPuRlc34Iqbg0",
  authDomain: "ngprc-2024.firebaseapp.com",
  projectId: "ngprc-2024",
  storageBucket: "ngprc-2024.appspot.com",
  messagingSenderId: "658532720522",
  appId: "1:658532720522:web:21c2db643ae2044dfef273",
  measurementId: "G-5NFFGPEF0F",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);
// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(firebaseApp);

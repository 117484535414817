import React, { useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const FloatingButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    AOS.refresh();
  };

  return (
    <>
      <button onClick={toggleExpanded} className="btn floating-button open-close-button">
        <i className={isExpanded ? 'icon-close' : 'icon-message'}></i>
      </button>

      {isExpanded && (
        <>
          <a
            href="tel:+919205546009"
            className="floating-button call-button icon-phone"
            data-aos="fade-up"
            data-aos-delay="200"
            aria-label="Call us"
          ></a>
          <a
            href="https://wa.me/+919205546009"
            className="floating-button whatsapp-button icon-whatsapp"
            data-aos="fade-up"
            data-aos-delay="400"
            aria-label="Message us on WhatsApp"
          ></a>
        </>
      )}
    </>
  );
};

export default FloatingButton;
import React from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import img1 from "../images/Edited/Landing page/why_choose_us_section_image_1.jpg";
import img2 from "../images/Edited/Landing page/why_choose_us_section_image_2.jpg";

const CounterComponent = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Counter will only animate once
  });

  return (
    <div className="block-half-content-1 d-block d-lg-flex mt-5">
      <div
        className="block-half-content-img"
        style={{ backgroundImage: `url(${img1})` }}
      ></div>
      <div
        className="block-half-content-text bg-primary"
        style={{
          backgroundImage: `linear-gradient(rgba(84, 116, 186, 0.9), rgba(84, 116, 186, 0.6)), url(${img2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="block-half-content-text-inner">
          <h2 className="block-half-content-heading mb-4">Why Choose Us</h2>
          <div className="block-half-content-excerpt">
            <p className="lead">
              Experience the difference of expert guidance tailored to your
              unique needs, ensuring a journey to recovery that's as effective
              as it is comfortable.
            </p>
          </div>
        </div>
        <div className="block-counter-1 section-counter">
          <div className="row">
            <div className="col-sm-4">
              <div className="counter">
                <div className="number-wrap">
                  <span className="block-counter-1-number" ref={ref}>
                    <CountUp end={inView ? 500 : 0} duration={5} suffix="+" />
                  </span>
                </div>
                <span className="block-counter-1-caption">Happy Patients</span>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="counter">
                <div className="number-wrap">
                  <span className="block-counter-1-number" ref={ref}>
                    <CountUp end={inView ? 12 : 0} duration={5} suffix="+" />
                  </span>
                </div>
                <span className="block-counter-1-caption">
                  Years of Experience
                </span>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="counter">
                <div className="number-wrap">
                  <span className="block-counter-1-number" ref={ref}>
                    <CountUp end={inView ? 98 : 0} duration={5} suffix="%" />
                  </span>
                </div>
                <span className="block-counter-1-caption">Satisfaction</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterComponent;

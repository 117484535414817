import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/NextGen-Name-with-Favicon.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="site-wrap">
        <div className="site-mobile-menu">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle" />
            </div>
          </div>
          <div className="site-mobile-menu-body" />
        </div>
        <header className={`site-navbar ${scrolled ? 'fixed' : ''}`} role="banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-10 col-xl-4">
                <div className="mb-0">
                  <NavLink to="/">
                    <img src={logo} alt="" className="site-logo" />
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-md-8 d-none d-xl-block">
                <nav
                  className="site-navigation position-relative text-right"
                  role="navigation"
                >
                  <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
                    <li>
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive ? "active-link" : ""
                        }
                      >
                        <span>Home</span>
                      </NavLink>
                    </li>
                    <li className="has-children">
                      <NavLink
                        to="/services"
                        className={({ isActive }) =>
                          isActive ? "active-link" : ""
                        }
                      >
                        <span>Services</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/about"
                        className={({ isActive }) =>
                          isActive ? "active-link" : ""
                        }
                      >
                        <span>About</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contact"
                        className={({ isActive }) =>
                          isActive ? "active-link" : ""
                        }
                      >
                        <span>Contact</span>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* Mobile Menu */}
              <div
                ref={menuRef}
                className={`d-inline-block col-2 d-xl-none ml-md-0 mr-auto py-3 ${
                  isMenuOpen ? "offcanvas-menu" : ""
                }`}
                style={{ position: "relative", top: 3 }}
              >
                <div
                  className={`site-menu-toggle js-menu-toggle text-white ${
                    isMenuOpen ? "active" : ""
                  }`}
                  onClick={toggleMenu}
                >
                  <span className="icon-menu h3" />
                </div>
                <div className="site-mobile-menu">
                  {isMenuOpen && (
                    <div className="site-mobile-menu">
                      <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3">
                          <span
                            className="icon-close2 js-menu-toggle"
                            onClick={toggleMenu}
                          ></span>
                        </div>
                      </div>
                      <div className="site-mobile-menu-body">
                        <ul className="site-nav-wrap">
                          <li>
                            <NavLink to="/" onClick={toggleMenu}>
                              <span>Home</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/services" onClick={toggleMenu}>
                              <span>Services</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/about" onClick={toggleMenu}>
                              <span>About</span>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/contact" onClick={toggleMenu}>
                              <span>Contact</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Navbar;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
  const arrowStyles = {
    position: "absolute",
    bottom: "-50px",
    fontSize: "30px",
    color: "#000",
    zIndex: "1",
  };

  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <span
        className="icon-arrow_forward"
        style={{ ...arrowStyles, right: "40%" }}
        onClick={onClick}
      />
    );
  }

  function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
      <span
        className="icon-arrow_back"
        style={{ ...arrowStyles, left: "40%" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    draggable: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="site-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <h2 className="site-section-heading text-center font-secondary">
              Happy Patients
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...settings}>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">1</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Varun Singh Bhati</h2>
                  <blockquote>
                    It was an nice experience with the dr. sir as I had pain in
                    my back and it got resolved within very less days and I
                    would like to recommend that one should try the
                    physiotherapy experience for better relief.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">2</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Pawan</h2>
                  <blockquote>
                    NextGen Physiotherapy center is the best center in Greater
                    Noida . I have suffered from back pain since one year and
                    taken treatment from different different center in Greater
                    Noida, when I heard about this center I came here and taken
                    10 days treatment. Now I m feeling perfectly fine . I say
                    thank you to my well behaved , Sincere, Sound knowledgeable
                    Physiotherapist.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">3</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Ritanshu Saxena</h2>
                  <blockquote>
                    Hi, I fortunately met Dr Sujeeth and Dr Palak in camp after
                    one month of my ACL injury. Their expertise in ACL heals up
                    physiotherapy has miraculously cured my left leg. In my 40
                    days of physio session, my knee range and strength are all
                    regained. Thank you NextGen Team.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">4</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Sujay Israni</h2>
                  <blockquote>
                    I was suffering from hamstring stiffness and a spine bulging
                    in my L4-L5 Disc followed by severe pain. I got to know
                    about NextGen Physiotherapy which is in Alpha 2 via online
                    research. Dr. Sujeet and his team has done a fabulous job in
                    treating my problem and now I feel 90-95% better. Special
                    mention to Dr. Lucky for his salient efforts during my
                    treatment course. Hats off to Dr. Sujeet and team.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">5</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Lucky</h2>
                  <blockquote>
                    I came here for my post fracture case of wrist now I have
                    taken my session for around 20 days. Now I'm feeling much
                    better and I recommend everyone. Thankyou NextGen for
                    helping me throughout my recovery phase.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">6</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Mahesh Phaugat</h2>
                  <blockquote>
                    I came here for my neck pain. Dr. Sujeet Suggested me for 10
                    days treatment now my 10 visits are completed and I got 80 %
                    relief. Thanks NextGen Team and all the best for future
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">7</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Palak Sharma</h2>
                  <blockquote>
                    It was a great experience and my injuries have healed in a
                    very less time. I 100% would recommend NextGen Physiotherapy
                    Center
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">8</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Gudda Pradhan</h2>
                  <blockquote>
                    Very nice physiotherapy treatment given by Dr. Sujeet came
                    here for sciatica L4 L5 disc pain. Now I got 70 percent
                    relief and it's more getting better day by day. Thanks
                    NextGen for such a wonderful treatment
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">9</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Navdeep Kumar</h2>
                  <blockquote>
                    I came here for lower back pain. After few days I am feeling
                    better and very nice treatment given by Dr. Lucky. All the
                    best for future.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">10</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Pradip Sharma</h2>
                  <blockquote>
                    I came here for lower back pain. After few days I am feeling
                    better, and my pain reduced. Very nice treatment given by
                    Dr. Sujeet and his team I wish them all the best for future.
                    I am very satisfied with the treatment.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">11</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Aryan Vivek</h2>
                  <blockquote>
                    I had a prolonged injury pain for almost a year. I came here
                    for the treatment and the staff treated me well and helped
                    me to get better with different advanced and customized
                    techniques.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">12</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Deepak Singh</h2>
                  <blockquote>
                    I had a wrist Injury, within few days session I was feeling
                    much better. Best place for physiotherapy
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">13</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Suraj Thapa</h2>
                  <blockquote>
                    I came for my neck pain after few days I am feeling better.
                    All the best NGPRC team..
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">14</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Prakash Singh</h2>
                  <blockquote>
                    Best Physiotherapy in Greater Noida. Dr. Sujeet is the best
                    physiotherapist.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">15</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Monu Lucky Ahmed</h2>
                  <blockquote>
                    I came here for my backpain and after few days it is
                    completely gone. Thanks doctor such a nice treatment. All
                    the best NGPRC.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">16</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Shivani Rana</h2>
                  <blockquote>
                    It's great Physiotherapy Center at very good price and the
                    doctor is also have very good experience it’s very helpful
                    for me please try at least one time.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">17</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Chandni Kumari</h2>
                  <blockquote>
                    Best physiotherapy centre in Greater Noida. Highly
                    recommended for all.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">18</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Ritish Bhati</h2>
                  <blockquote>
                    One of the best physiotherapy in the town. Must take an
                    appointment.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">19</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Adarsh Tiwari</h2>
                  <blockquote>
                    Best physiotherapy clinic in Greater Noida.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">20</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Abhishek Upadhyay</h2>
                  <blockquote>
                    One of the great Physiotherapy centre at Greater Noida.
                  </blockquote>
                </div>
              </div>
              <div className="d-block block-testimony mx-auto text-center testimonial">
                <div className="slide-number">21</div>
                <div className="person w-25 mx-auto mb-4">
                  <span
                    className="icon icon-user-circle-o"
                    style={{ fontSize: "3em" }}
                  />
                </div>
                <div>
                  <h2 className="h5 mb-4">Devinder Mehra</h2>
                  <blockquote>
                    It was good. I am very much satisfied with the procedure.
                  </blockquote>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
